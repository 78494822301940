const about = `
1     /** 
2     * About me
3     * i am a computer science student
4     * and a web developer in free time
5     * i like to learn new things
6     * and i am always open to new ideas
8    */
`;
const bio= `
1     /** 
2     * About me
3     * i am a computer science student
4     * and a web developer in free time
5     * i like to learn new things
6     * and i am always open to new ideas
8    */
`;
const interest = `
1     /**
2     * Interest
3     * lorem ipsum dolor sit amet
4     * consectetur adipiscing elit
5     * sed do eiusmod tempor incididunt
6     * ut labore et dolore magna aliqua
7     * ut enim ad minim veniam
8     * quis nostrud exercitation ullamco
9     * laboris nisi ut aliquip ex ea commodo
10    * consequat duis aute irure dolor
11    * in reprehenderit in voluptate velit
12    * esse cillum dolore eu fugiat nulla
13    * pariatur excepteur sint occaecat
14    * cupidatat non proident sunt in culpa
15    * qui officia deserunt mollit anim id
16    * est laborum
17    */`;
const skills = `
1     /**
2     * Skills
3     * My skills range from
4     * Problem solving and algorithms
5     * to app development using flutter 
6     * and web development using react
17    */
`;
const experience = `
1     /**
2     * Experience
3     * lorem ipsum dolor sit amet
4     * consectetur adipiscing elit
5     * sed do eiusmod tempor incididunt
6     * ut labore et dolore magna aliqua
7     * ut enim ad minim veniam
8     * quis nostrud exercitation ullamco
9     * laboris nisi ut aliquip ex ea commodo
10    * consequat duis aute irure dolor
11    * in reprehenderit in voluptate velit
12    * esse cillum dolore eu fugiat nulla
13    * pariatur excepteur sint occaecat
14    * cupidatat non proident sunt in culpa
15    * qui officia deserunt mollit anim id
16    * est laborum
17    */
`;
const education = `
1     /**
2     * Education
3     * lorem ipsum dolor sit amet
4     * consectetur adipiscing elit
5     * sed do eiusmod tempor incididunt
6     * ut labore et dolore magna aliqua
7     * ut enim ad minim veniam
8     * quis nostrud exercitation ullamco
9     * laboris nisi ut aliquip ex ea commodo
10    * consequat duis aute irure dolor
11    * in reprehenderit in voluptate velit
12    * esse cillum dolore eu fugiat nulla
13    * pariatur excepteur sint occaecat
14    * cupidatat non proident sunt in culpa
15    * qui officia deserunt mollit anim id
16    * est laborum
17    */
`;
const certificates = `
1     /**
2     * Certificates
3     * lorem ipsum dolor sit amet
4     * consectetur adipiscing elit
5     * sed do eiusmod tempor incididunt
6     * ut labore et dolore magna aliqua
7     * ut enim ad minim veniam
8     * quis nostrud exercitation ullamco
9     * laboris nisi ut aliquip ex ea commodo
10    * consequat duis aute irure dolor
11    * in reprehenderit in voluptate velit
12    * esse cillum dolore eu fugiat nulla
13    * pariatur excepteur sint occaecat
14    * cupidatat non proident sunt in culpa
15    * qui officia deserunt mollit anim id
16    * est laborum
17    */
`;
const hobbies = `
1     /**
2     * Hobbies
3     * lorem ipsum dolor sit amet
4     * consectetur adipiscing elit
5     * sed do eiusmod tempor incididunt
6     * ut labore et dolore magna aliqua
7     * ut enim ad minim veniam
8     * quis nostrud exercitation ullamco
9     * laboris nisi ut aliquip ex ea commodo
10    * consequat duis aute irure dolor
11    * in reprehenderit in voluptate velit
12    * esse cillum dolore eu fugiat nulla
13    * pariatur excepteur sint occaecat
14    * cupidatat non proident sunt in culpa
15    * qui officia deserunt mollit anim id
16    * est laborum
17    */
`;
export {experience , skills , certificates , bio , interest, education, hobbies, about};